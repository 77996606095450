.confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9); /* Light background with slight opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.confirmation-modal {
    background: #f7f7f7; /* Light grey background for a soft, clean look */
    padding: 30px; /* Increased padding for a more spacious feel */
    border-radius: 12px; /* Rounded corners for a modern aesthetic */
    text-align: center;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Softer shadow for a more subtle effect */
    max-width: 400px; /* Constrain width for a more compact design */
    width: 100%;
}

.confirmation-buttons {
    margin-top: 30px; /* Added more space above buttons for better visual separation */
    display: flex; /* Flexbox to align buttons */
    justify-content: center; /* Center-align buttons */
}

.confirm-button, .cancel-button {
    padding: 12px 20px; /* Larger padding for a more substantial button feel */
    margin: 0 10px;
    border: none;
    border-radius: 20px; /* More rounded corners for buttons */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease; /* Added transition for hover effect */
    font-weight: bold; /* Bold text for better readability */
}

.confirm-button {
    background-color: #1db954; /* Spotify's signature green but lighter */
    color: white;
}

.cancel-button {
    background-color: #e0e0e0; /* Light grey for cancel button */
    color: #333;
}

.confirm-button:hover {
    background-color: #17a44a; /* Darker green for hover effect */
    transform: translateY(-2px); /* Slight lift effect on hover */
}

.cancel-button:hover {
    background-color: #d0d0d0; /* Slightly darker grey on hover */
    transform: translateY(-2px); /* Slight lift effect on hover */
}

.confirm-button:active, .cancel-button:active {
    transform: translateY(0); /* Reset transform on active for a subtle press effect */
}
