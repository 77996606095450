/* loading.css */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
}

.dot-pulse {
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #3498db;
    color: #3498db;
    animation: dotPulse 1.5s infinite linear both;
    opacity: 0;
}

.dot-pulse::before, .dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #3498db;
    color: #3498db;
    opacity: 0;
}

.dot-pulse::before {
    left: -15px;
    animation: dotPulseBefore 1.5s infinite linear both;
}

.dot-pulse::after {
    left: 15px;
    animation: dotPulseAfter 1.5s infinite linear both;
}

@keyframes dotPulseBefore {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes dotPulse {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes dotPulseAfter {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    60% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}