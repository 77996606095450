/* VerifyEmailPage.css */

.verify-email-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(135deg, #f6f8fa, #eaeef3);
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

.verify-email-container h1 {
    color: #1db954; /* Spotify green */
    font-size: 2em;
    margin-bottom: 20px;
    font-weight: 600;
}

.verify-email-container p {
    color: #333;
    font-size: 1em;
    margin-bottom: 15px;
    line-height: 1.6;
    max-width: 600px;
}

.redirect-login-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #1db954; /* Spotify green */
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.redirect-login-button:hover {
    background-color: #17a74a; /* Slightly darker green */
}