/* Set the background color for the entire app */
body {
  background-color: #F0F0F0; /* Light neutral background */
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif; /* Modern, clean font */
}

/* General App Styling */
.App {
  text-align: center;
  padding: 20px;
  max-width: 1200px; /* Limit the width of the app */
  margin: 0 auto; /* Center the app horizontally */
  position: relative; /* Ensure relative positioning for the app container */
  display: flex;
  flex-direction: row;
}

.main-content {
  margin-left: 120px; /* Adjust based on the new width of your vertical navbar */
  padding: 20px;
  flex: 1;
}

/* Pomodoro Section */
.pomodoro-timer {
  margin-bottom: 20px;
}

.timer-display {
  font-size: 2.5rem;
  margin-bottom: 10px;
  font-weight: 300; /* Thin font for a sleek look */
  color: #1DB954; /* Spotify green for contrast */
}

.timer-button {
  background-color: #1DB954; /* Spotify green */
  border: none;
  border-radius: 25px; /* Pill-shaped buttons */
  width: 70px;
  height: 40px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  margin: 5px;
  outline: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-family: 'Roboto', sans-serif;
}

.timer-button.active {
  background-color: #1AA34A; /* Slightly darker green for active state */
}

.timer-button:hover,
.reset-button:hover,
.signout-button:hover {
  transform: scale(1.08); /* Slightly larger scale for better interaction */
  background-color: #1AA34A; /* Slightly darker green on hover */
}

.reset-button {
  background-color: #FFB74D; /* Light orange */
  border: none;
  border-radius: 25px;
  width: 70px;
  height: 40px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  margin: 5px;
  outline: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-family: 'Roboto', sans-serif;
}

/* Sections - Styled prominently in a single row */
.sections {
  display: flex;
  justify-content: center;
  gap: 30px; /* Space between each section */
  margin-bottom: 30px;
}

.section-button {
  background: linear-gradient(135deg, #A7FFEB, #FAFFD1); /* Light green to yellow gradient */
  border-radius: 25px; /* Pill-shaped buttons */
  padding: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none; /* Remove border */
}

.section-button.active {
  box-shadow: 0 0 15px rgba(0, 255, 140, 0.7); /* Green glow */
  transform: scale(1.1); /* Slightly larger and rotates on active */
  background: linear-gradient(135deg, #B2FFDA, #E0FFD1); /* Softer green when active */
}

.section-button svg {
  width: 60px; /* Adjust size for section icons */
  height: 60px;
  fill: #1DB954; /* Spotify green for icons */
  transition: fill 0.3s ease;
}

.section-button h3 {
  font-size: 1rem; /* Consistent font size */
  font-weight: 500; /* Medium weight */
  color: #333; /* Darker color for text */
  margin-top: 10px;
  transition: color 0.3s ease;
}

.section-button.active svg {
  fill: #B2FFDA; /* Lighter green for the icon when active */
}

.section-button.active h3 {
  color: #B2FFDA; /* Change text color when active */
}

.section-button:hover {
  transform: scale(1.05); /* Slight hover effect */
}

/* Sound Board Grid */
.sound-board {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns for sounds */
  gap: 20px; /* Slightly increased spacing */
  padding: 20px; /* Added padding for better spacing */
  justify-content: center; /* Center the sound board grid */
}

/* Sound Card Styling */
.sound-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px; /* Smaller padding for sounds */
  background-color: #FFF; /* White background for cards */
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sound-card h3 {
  margin-top: 8px;
  font-size: 0.9rem; /* Consistent font size */
  color: #333; /* Dark gray for readability */
}

/* Default (inactive) state for sound icons */
.inactive-sound {
  opacity: 0.5; /* Semi-transparent to indicate inactivity */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Active state for sound icons */
.icon-button.active svg {
  fill: #FF9A76; /* Soft orange for active sound icons */
}

/* Icon Button Styling */
.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;
}

.icon-button svg {
  width: 50px; /* Adjust icon size */
  height: 50px;
  transition: transform 0.3s ease, fill 0.3s ease;
}

.icon-button:hover svg {
  transform: scale(1.05); /* Slightly larger on hover */
}

/* Sign Out Button Styling */
.signout-button {
  background-color: #FFB74D; /* Light orange */
  color: white;
  border: none;
  border-radius: 25px; /* Pill-shaped button */
  padding: 12px 25px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-family: 'Roboto', sans-serif; /* Consistent font */
}

.signout-button:hover {
  background-color: #FFA726; /* Darker orange on hover */
  transform: scale(1.05); /* Slight hover effect */
}

/* Back to Sign In Link */
.back-to-signin-link {
  margin-top: 15px;
  color: #FFB74D;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.back-to-signin-link:hover {
  color: #FFA726; /* Darker orange on hover */
}

/* Media Queries for Responsiveness */

/* For tablets and below */
@media (max-width: 768px) {
  .App {
    flex-direction: column;
    padding: 10px;
  }

  .main-content {
    margin-left: 0;
    padding: 10px;
  }

  .sections {
    flex-direction: column;
    gap: 15px;
  }

  .sound-board {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for sounds */
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .timer-display {
    font-size: 2rem;
  }

  .section-button {
    padding: 10px;
  }

  .section-button svg {
    width: 50px;
    height: 50px;
  }

  .sound-board {
    grid-template-columns: 1fr; /* 1 column for sounds */
  }

  .timer-button,
  .reset-button {
    width: 60px;
    height: 35px;
    font-size: 0.875rem;
  }

  .signout-button {
    padding: 10px 20px;
    font-size: 0.875rem;
  }
}
