.global-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    background: linear-gradient(135deg, #f6f9fc, #e9f1f7);
    border-radius: 16px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1), 0 1px 8px rgba(0, 0, 0, 0.05);
    max-width: 700px;
    margin: 30px auto;
    transition: all 0.3s ease;
}

.global-control-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 24px;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(74, 144, 226, 0.3);
}

.global-control-button svg {
    width: 24px;
    height: 24px;
    margin-right: 12px;
}

.global-control-button:hover {
    background-color: #3a7bc8;
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(74, 144, 226, 0.4);
}

.global-control-button:disabled {
    background-color: #b0c4de;
    cursor: not-allowed;
    box-shadow: none;
}

.global-control-button.play {
    background-color: #4caf50;
}

.global-control-button.pause {
    background-color: #ff9800;
}

.global-volume-control {
    display: flex;
    align-items: center;
    gap: 20px;
}

.volume-icon {
    font-size: 24px;
    color: #4a90e2;
}

.global-volume-control input {
    width: 200px;
    cursor: pointer;
    appearance: none;
    height: 8px;
    background: #d1e2f7;
    border-radius: 4px;
    outline: none;
    transition: all 0.3s ease;
}

.global-volume-control input:hover {
    background: #b8d3f2;
}

.global-volume-control input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #4a90e2;
    cursor: pointer;
    box-shadow: 0 2px 8px rgba(74, 144, 226, 0.5);
    transition: all 0.3s ease;
}

.global-volume-control input::-moz-range-thumb {
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 50%;
    background: #4a90e2;
    cursor: pointer;
    box-shadow: 0 2px 8px rgba(74, 144, 226, 0.5);
    transition: all 0.3s ease;
}

.global-volume-control input::-webkit-slider-thumb:hover,
.global-volume-control input::-moz-range-thumb:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 12px rgba(74, 144, 226, 0.6);
}

.global-volume-value {
    font-size: 18px;
    font-weight: 600;
    color: #4a90e2;
    min-width: 50px;
    text-align: center;
}

@media (max-width: 600px) {
    .global-controls {
        flex-direction: column;
        gap: 20px;
    }

    .global-volume-control {
        width: 100%;
        justify-content: space-between;
    }
}