/* Settings Container */
.settings-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background: #ffffff; /* Bright white background for a clean look */
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Light shadow for subtle depth */
  font-family: 'Circular', sans-serif; /* Spotify's signature font */
  font-weight: 300; /* Thinner font for overall text */
  color: #333333; /* Dark gray text for readability */
  text-align: center;
}

/* Settings Header */
.settings-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Circular', sans-serif;
  font-weight: 600; /* Slightly bolder for the heading */
  font-size: 1.8rem; /* Larger font size */
  color: #1DB954; /* Spotify green for the heading */
}

/* Form Group */
.settings-form .form-group {
  margin-bottom: 20px;
}

/* Form Labels */
.settings-form label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #666666; /* Soft gray for labels */
  font-family: 'Circular', sans-serif;
  font-weight: 300; /* Thinner font style */
}

/* Input Fields */
.settings-form input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #cccccc; /* Light gray border for input fields */
  border-radius: 6px;
  background-color: #ffffff; /* White background for inputs */
  color: #333333; /* Dark text inside input */
  font-family: 'Circular', sans-serif;
  font-weight: 300; /* Thinner font style */
  transition: border-color 0.3s ease;
}

.settings-form input:focus {
  border-color: #1DB954; /* Green border on focus */
}

/* Theme Options Container */
.settings-form .theme-options {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Space between theme options */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

/* Individual Theme Option */
.theme-option {
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 6px;
  background-color: #f7f7f7; /* Light gray background for options */
  color: #333333; /* Dark gray text */
  font-family: 'Circular', sans-serif;
  font-weight: 300; /* Thinner font style */
  transition: background-color 0.3s ease, color 0.3s ease;
  flex: 1; /* Allow the options to grow/shrink equally */
  text-align: center; /* Center the text */
  margin: 5px 0; /* Vertical margin to separate rows */
}

/* Selected Theme Option */
.theme-option.selected {
  background-color: #1DB954; /* Spotify green for selected option */
  color: #ffffff; /* White text for selected option */
}

/* Hover Effect for Theme Option */
.theme-option:hover {
  background-color: #e9e9e9; /* Slightly darker gray on hover */
  color: #333333; /* Dark text on hover */
}

/* Save Button */
.save-button {
  width: 100%;
  padding: 10px;
  background-color: #1DB954; /* Spotify green for the button */
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-family: 'Circular', sans-serif;
  font-weight: 600; /* Bolder text for button */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Hover Effect for Save Button */
.save-button:hover {
  background-color: #1ed760; /* Slightly lighter green on hover */
}

/* Media Queries for Responsiveness */

/* Tablet and small desktop */
@media screen and (max-width: 768px) {
  .settings-container {
    padding: 15px;
    max-width: 100%;
  }

  .settings-container h2 {
    font-size: 1.6rem;
  }

  .settings-form input {
    padding: 8px;
    font-size: 13px;
  }

  .theme-option {
    padding: 8px 12px;
    font-size: 13px;
  }

  .save-button {
    padding: 8px;
    font-size: 0.9rem;
  }
}

/* Mobile devices */
@media screen and (max-width: 480px) {
  .settings-container {
    padding: 10px;
  }

  .settings-container h2 {
    font-size: 1.4rem;
  }

  .settings-form input {
    padding: 7px;
    font-size: 12px;
  }

  .theme-option {
    padding: 7px 10px;
    font-size: 12px;
  }

  .save-button {
    padding: 7px;
    font-size: 0.85rem;
  }
}

/* Extra small devices */
@media screen and (max-width: 320px) {
  .settings-container {
    padding: 8px;
  }

  .settings-container h2 {
    font-size: 1.2rem;
  }

  .settings-form input {
    padding: 6px;
    font-size: 11px;
  }

  .theme-option {
    padding: 6px 8px;
    font-size: 11px;
  }

  .save-button {
    padding: 6px;
    font-size: 0.8rem;
  }
}

/* Global Controls */
.global-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 12px;
  margin-bottom: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  gap: 20px;
}

.global-controls:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Pause Button */
.global-pause-button {
  display: flex;
  align-items: center;
  background-color: #3498db; /* Updated background color */
  color: #ffffff;
  padding: 12px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.global-pause-button:hover {
  background-color: #2980b9; /* Updated background color on hover */
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.global-pause-button svg {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  fill: none; /* Removed unnecessary stroke attribute */
}

.global-pause-button span {
  /* Added text for pause button */
}

/* Volume Control */
.global-volume-control {
  display: flex;
  align-items: center;
  gap: 12px;
}

.global-volume-control label {
  font-size: 1rem;
  font-weight: 500;
  color: #3498db; /* Updated color to match pause button */
}

.global-volume-control input {
  width: 160px;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 12px;
  appearance: none;
  outline: none;
  transition: background-color 0.3s ease;
}

.global-volume-control input::-webkit-slider-thumb {
  appearance: none;
  width: 16px;
  height: 16px;
  background-color: #3498db;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.global-volume-control input::-webkit-slider-thumb:hover {
  background-color: #2980b9;
}

.global-volume-value {
  font-size: 1rem;
  font-weight: bold;
  color: #004d40;
}