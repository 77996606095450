/* Newsletter.css */

.newsletter {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f9fafc;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.newsletter * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.newsletter__container {
    background-color: #ffffff;
    border-radius: 16px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 90%;
    max-width: 500px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.newsletter__container:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 36px rgba(0, 0, 0, 0.15);
}

.newsletter__header {
    background: linear-gradient(135deg, #ff9a9e, #fad0c4);
    color: #333;
    padding: 2rem;
    text-align: center;
    border-bottom: 2px solid #f3f4f6;
}

.newsletter__title {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.newsletter__subtitle {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #555;
}

.newsletter__main {
    padding: 2rem;
    background-color: #ffffff;
}

.newsletter__benefits {
    margin-bottom: 2rem;
    background-color: #f3f4f6;
    padding: 1rem;
    border-radius: 8px;
}

.newsletter__list {
    list-style-type: none;
    padding-left: 1rem;
}

.newsletter__list-item {
    margin-bottom: 0.75rem;
    padding-left: 1.5rem;
    position: relative;
    font-weight: 500;
    color: #666;
}

.newsletter__list-item::before {
    content: '✔';
    color: #ff6b6b;
    position: absolute;
    left: 0;
    font-size: 1.2rem;
}

.newsletter__sign-up-form {
    background-color: #f3f4f6;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
}

.newsletter__form {
    display: flex;
    flex-direction: column;
}

.newsletter__form-group {
    margin-bottom: 1rem;
}

.newsletter__label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: #333;
}

.newsletter__input {
    width: 100%;
    padding: 0.75rem;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.newsletter__input:focus {
    outline: none;
    border-color: #ff6b6b;
    box-shadow: 0 0 0 4px rgba(255, 107, 107, 0.1);
}

.newsletter__input--invalid {
    border-color: #dc3545;
}

.newsletter__error-message {
    display: block;
    color: #dc3545;
    font-size: 0.875rem;
    margin-top: 0.25rem;
}

.newsletter__button {
    padding: 0.75rem;
    background: linear-gradient(135deg, #ff6b6b, #f5a623);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.newsletter__button:hover {
    background: linear-gradient(135deg, #f5a623, #ff6b6b);
    transform: scale(1.02);
}

.newsletter__footer {
    text-align: center;
    padding: 1rem;
    color: #6c757d;
    font-size: 0.875rem;
    background-color: #ffffff;
}

/* Toastify styles */
.newsletter__toastify {
    padding: 12px 20px;
    color: #ffffff;
    font-size: 14px;
    border-radius: 4px;
}

.newsletter__toastify--success {
    background: linear-gradient(135deg, #28a745, #218838);
}

.newsletter__toastify--error {
    background: linear-gradient(135deg, #dc3545, #c82333);
}