/* Predefined Sessions Container */
.predefined-sessions {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* Six session cards per row */
    gap: 20px;
    padding: 20px;
    background-color: #f5f7fa; /* Softer blue-gray background for a calmer feel */
    color: #333; /* Dark gray text for better readability */
    margin-top: 30px; /* Increased top margin to provide more space below the tabs */
    max-width: 1200px; /* Adjusted width to fit 6 session cards comfortably */
    width: 100%; /* Ensure it stretches across the available width */
    justify-content: center; /* Center the session cards horizontally */
    border-radius: 15px; /* Rounded corners for the container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
}

/* Session Card Styles */
.session-card {
    background-color: #ffffff; /* Bright white for session cards */
    padding: 15px;
    border-radius: 16px; /* Softer, more rounded corners */
    border: 1px solid #b2dfdb; /* Thinner, lighter teal border */
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
    height: 100px;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #00897b; /* Darker teal text for session names */
    font-weight: 400; /* Normal font weight for better visibility */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.session-card:hover {
    transform: scale(1.08); /* Slightly larger zoom */
    background-color: #e0f2f1; /* Softer teal background on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); /* More pronounced shadow on hover */
    border-color: #26a69a; /* Darker teal border on hover */
}

/* Active Session Card */
.session-card.active {
    background-color: #26a69a; /* Darker teal when active */
    color: #ffffff; /* Change text color to white when active */
    transform: scale(1.12);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    border: none;
}

.session-card.active h3 {
    color: #ffffff; /* Ensure text is white when session is active */
}

.session-card.active::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background: linear-gradient(45deg, #26a69a, transparent);
    border-radius: 16px; /* Match the rounded corners of the card */
    animation: music-wave 1.5s infinite linear;
    z-index: -1;
}

@keyframes music-wave {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(1.5);
        opacity: 0;
    }
}

/* Paused Session Card */
.session-card.paused::before {
    animation: none;
    opacity: 0;
}

/* Session Card Icon */
.session-icon {
    font-size: 2.2rem; /* Slightly larger icon size for emphasis */
    margin-bottom: 10px;
}

/* Session Card Text */
.session-card h3 {
    font-size: 1.1rem; /* Adjusted font size for readability */
    color: #00897b; /* Darker teal text */
    font-weight: 400; /* Regular font style for better visibility */
    font-family: 'Roboto', sans-serif; /* Friendly, modern font */
    margin: 0;
    line-height: 1.2;
}

/* Add Session Button */
.add-session {
    background-color: #e0f2f1; /* Soft teal background for add session */
    color: #00897b; /* Matching teal for text */
    font-size: 2rem; /* Slightly larger font size */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    border-radius: 16px; /* Softer rounded corners */
    border: 1px dashed #26a69a; /* Dashed, darker teal border */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease, border-color 0.3s ease;
}

.add-session:hover {
    background-color: #b2dfdb; /* Lighter teal background on hover */
    border-color: #00897b; /* Solid border on hover */
}

/* Delete Button on Combination Cards */
.delete-combination-button {
    position: absolute;
    top: 8px;
    right: 8px;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
}

.delete-combination-button img {
    width: 18px;
    height: 18px;
    filter: brightness(0) invert(1);
    transition: transform 0.2s ease;
}

.delete-combination-button img:hover {
    transform: scale(1.15);
}

/* Media Queries for Responsiveness */

/* Tablet and small desktop */
@media screen and (max-width: 1024px) {
    .predefined-sessions {
        grid-template-columns: repeat(4, 1fr); /* Four session cards per row */
    }

    .session-card,
    .add-session {
        height: 90px;
        width: 90px;
    }

    .session-card h3 {
        font-size: 0.9rem;
    }

    .session-icon {
        font-size: 2rem;
    }
}

/* Mobile devices */
@media screen and (max-width: 768px) {
    .predefined-sessions {
        grid-template-columns: repeat(3, 1fr); /* Three session cards per row */
    }

    .session-card,
    .add-session {
        height: 80px;
        width: 80px;
    }

    .session-card h3 {
        font-size: 0.85rem;
    }

    .session-icon {
        font-size: 1.8rem;
    }
}

/* Extra small devices */
@media screen and (max-width: 480px) {
    .predefined-sessions {
        grid-template-columns: repeat(2, 1fr); /* Two session cards per row */
        gap: 15px; /* Reduce gap between session cards */
    }

    .session-card,
    .add-session {
        height: 70px;
        width: 70px;
    }

    .session-card h3 {
        font-size: 0.75rem;
    }

    .session-icon {
        font-size: 1.6rem;
    }
}

/* Very small devices */
@media screen and (max-width: 320px) {
    .predefined-sessions {
        grid-template-columns: repeat(1, 1fr); /* One session card per row */
        gap: 10px; /* Reduce gap further */
    }

    .session-card,
    .add-session {
        height: 60px;
        width: 60px;
    }

    .session-card h3 {
        font-size: 0.65rem;
    }

    .session-icon {
        font-size: 1.4rem;
    }
}

/* Tooltip styles */
[data-tooltip] {
    position: relative; /* Ensure tooltips are positioned correctly */
    cursor: pointer; /* Indicate interactable elements */
}

[data-tooltip]:hover::before,
[data-tooltip]:hover::after {
    display: block; /* Show tooltips on hover */
}

[data-tooltip]::before {
    content: attr(data-tooltip); /* Display tooltip text from data attribute */
    position: absolute;
    bottom: 100%; /* Position above the element */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.75); /* Semi-transparent dark background */
    color: #fff; /* White text for contrast */
    padding: 6px 10px;
    border-radius: 5px;
    font-size: 0.85rem; /* Slightly smaller font size */
    white-space: nowrap; /* Prevent wrapping */
    display: none; /* Hidden by default */
    z-index: 1000; /* Ensure it appears above other elements */
    opacity: 0; /* Start hidden */
    transition: opacity 0.3s ease; /* Smooth transition for showing/hiding */
}

[data-tooltip]::after {
    content: '';
    position: absolute;
    bottom: calc(100% - 5px); /* Position arrow below tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px; /* Size of the arrow */
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent; /* Arrow color */
    display: none; /* Hidden by default */
    z-index: 999; /* Ensure it appears above other elements */
    opacity: 0; /* Start hidden */
    transition: opacity 0.3s ease; /* Smooth transition for showing/hiding */
}

[data-tooltip]:hover::before,
[data-tooltip]:hover::after {
    opacity: 1; /* Show tooltip on hover */
}

/* Additional Content Section */
.additional-content {
    background-color: #f4f7fb; /* Light background for contrast */
    padding: 40px 20px; /* Extra padding for spaciousness */
    margin-top: 40px;
    border-radius: 15px; /* Soft rounded corners */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    max-width: 1200px; /* Restrict width for better readability */
    margin-left: auto; /* Center align */
    margin-right: auto;
}

/* Headings */
.additional-content h2, .additional-content h3 {
    color: #00796b; /* Darker teal for headings */
    font-family: 'Roboto', sans-serif;
    font-weight: 600; /* Bold headings */
    margin-bottom: 20px;
}

.additional-content h2 {
    font-size: 2rem; /* Larger font size for H2 */
    text-align: center; /* Center align main heading */
    margin-bottom: 30px; /* Larger bottom margin for spacing */
}

.additional-content h3 {
    font-size: 1.5rem; /* Slightly smaller than h2 */
    margin-top: 30px; /* Spacing before h3 */
}

/* Paragraph Styling */
.additional-content p {
    color: #444; /* Dark gray for better readability */
    line-height: 1.7; /* Increase line-height for improved spacing */
    font-size: 1.1rem; /* Slightly larger font size for readability */
    margin-bottom: 20px; /* Add space between paragraphs */
    font-family: 'Roboto', sans-serif;
}

/* Strong Text */
.additional-content strong {
    color: #004d40; /* Darker green for emphasized text */
    font-weight: 700; /* Bold for strong text */
}

/* Unordered List Styles */
.additional-content ul {
    margin: 20px 0;
    padding-left: 40px; /* Increase left padding for better list spacing */
    list-style-type: disc; /* Use disc for list markers */
}

.additional-content ul li {
    margin-bottom: 10px; /* Space between list items */
    font-size: 1.1rem;
    color: #555; /* Lighter gray for list items */
}

/* Link Styling */
.additional-content a {
    color: #00796b; /* Matching teal for links */
    text-decoration: underline;
    transition: color 0.3s ease;
}

.additional-content a:hover {
    color: #004d40; /* Darker teal on hover */
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .additional-content {
        padding: 30px 15px;
    }

    .additional-content h2 {
        font-size: 1.8rem;
    }

    .additional-content h3 {
        font-size: 1.3rem;
    }

    .additional-content p {
        font-size: 1rem;
    }

    .additional-content ul {
        padding-left: 30px;
    }
}