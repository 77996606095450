/* Song List Container */
.song-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Responsive grid layout */
    gap: 15px; /* Increased gap for better spacing */
    background-color: #f3f7fa; /* Soft background color for contrast */
    padding: 20px;
    border-radius: 15px; /* More rounded corners */
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1); /* Deeper shadow for depth */
    margin-top: 40px; /* Create distance between elements */
    max-width: 900px; /* Limit the width of the song list */
    width: 100%; /* Full width up to max width */
    justify-content: center; /* Center the song list container */
}

/* Individual Song Item */
.song-item {
    display: flex;
    align-items: center;
    gap: 12px; /* Space between icon and song name */
    padding: 12px;
    border-radius: 18px; /* Softer rounded corners */
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    background: linear-gradient(135deg, #ffffff, #e9f5f9); /* Gradient background for depth */
    border: 1px solid #66bb6a; /* Lighter green border */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08); /* Light shadow for song item */
}

/* Song Item Icon */
.song-item img {
    width: 36px; /* Slightly larger icons */
    height: 36px;
    border: 2px solid #66bb6a; /* Thicker border for icons */
    border-radius: 50%; /* Circular icons */
    background-color: #f5f5f5; /* Background color for icons */
    padding: 5px; /* Padding inside icon border */
    transition: transform 0.3s ease, border-color 0.3s ease; /* Smooth scale and color transition */
}

/* Song Item Text */
.song-item span {
    font-size: 1.1rem; /* Increased font size */
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-weight: 400; /* Regular font style */
    transition: color 0.3s ease; /* Smooth color transition */
}

/* Active Song Item - No Animation, Just Color Change */
.song-item.active {
    background-color: #a5d6a7; /* Brighter green background for active songs */
    font-weight: bold; /* Bold text for active songs */
    border: 2px solid #388e3c; /* Darker green border for active items */
    box-shadow: 0 0 15px rgba(56, 142, 60, 0.7), 0 0 30px rgba(56, 142, 60, 0.5); /* Stronger shadow for more emphasis */
}

/* Icon Color Change Based on Active State */
.song-item.active img {
    border-color: #388e3c; /* Darker border for active state */
    background-color: #a5d6a7; /* Change icon background for active state */
}

.song-item img {
    border-color: #bdbdbd; /* Grey border when not active */
    background-color: #f5f5f5; /* Icon background when not active */
}

/* Hover Effect for Song Items */
.song-item:hover {
    background-color: #e0f2f1; /* Lighter green on hover */
    cursor: pointer; /* Pointer cursor on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1); /* Shadow on hover */
}

/* Media Queries for Responsiveness */

/* Tablet and small desktop */
@media screen and (max-width: 1024px) {
    .song-list {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust for smaller screens */
        gap: 12px; /* Consistent gap */
    }

    .song-item span {
        font-size: 1rem; /* Smaller font size */
    }

    .song-item img {
        width: 32px;
        height: 32px;
    }
}

/* Mobile devices */
@media screen and (max-width: 768px) {
    .song-list {
        grid-template-columns: repeat(2, 1fr); /* Two items per row on mobile */
        gap: 10px; /* Maintain spacing */
    }

    .song-item span {
        font-size: 0.9rem; /* Smaller font size for mobile */
    }

    .song-item img {
        width: 28px;
        height: 28px;
    }
}

/* Extra small devices */
@media screen and (max-width: 480px) {
    .song-list {
        grid-template-columns: repeat(2, 1fr); /* Two items per row */
        gap: 8px; /* Reduce gap */
    }

    .song-item span {
        font-size: 0.85rem; /* Smaller font size */
    }

    .song-item img {
        width: 24px;
        height: 24px;
    }
}

/* Very small devices */
@media screen and (max-width: 320px) {
    .song-list {
        grid-template-columns: repeat(2, 1fr); /* Two items per row */
        gap: 6px; /* Further reduce gap */
    }

    .song-item span {
        font-size: 0.8rem; /* Smallest font size */
    }

    .song-item img {
        width: 22px;
        height: 22px;
    }
}

/* Spinner Container */
.spinner-square {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 60px; /* Width for spinner */
    height: 80px; /* Height for spinner */
    margin: 0 auto; /* Center horizontally */
}

/* Spinner Squares */
.spinner-square > .square {
    width: 14px; /* Square width */
    height: 60px; /* Square height */
    margin: 0 4px; /* Space between squares */
    border-radius: 4px; /* Rounded corners */
    background-color: rgb(111, 163, 240); /* Initial color */
    animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite; /* Smooth animation */
}

/* Individual Squares Animation */
.square-1 {
    animation-delay: 0s; /* First square animation */
}

.square-2 {
    animation-delay: 200ms; /* Second square delay */
}

.square-3 {
    animation-delay: 400ms; /* Third square delay */
}

/* Animation Keyframes */
@keyframes square-anim {
    0% {
        height: 60px; /* Initial height */
        background-color: rgb(111, 163, 240);
    }
    20% {
        height: 60px;
    }
    40% {
        height: 80px; /* Peak height */
        background-color: rgb(111, 200, 240); /* Lighter color at peak */
    }
    80% {
        height: 60px;
    }
    100% {
        height: 60px;
        background-color: rgb(111, 163, 240);
    }
}

/* Volume Adjuster */
.volume-adjuster {
    width: 100%; /* Full width for mobile friendliness */
    height: 4px; /* Thin height */
    background-color: #1DB954; /* Spotify green background */
    border-radius: 2px; /* Rounded edges */
    margin: 20px 0; /* Increased space around the slider for mobile */
    appearance: none; /* Remove default styling */
    outline: none; /* Remove focus outline */
}

/* Customize slider thumb */
.volume-adjuster::-webkit-slider-thumb {
    appearance: none;
    width: 14px; /* Slightly larger for easier touch control */
    height: 14px; /* Slightly larger for easier touch control */
    background: #1DB954; /* Spotify green for thumb */
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); /* Subtle shadow for thumb */
}

.volume-adjuster::-moz-range-thumb {
    width: 14px; /* Slightly larger for easier touch control */
    height: 14px; /* Slightly larger for easier touch control */
    background: #1DB954;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

/* Adjust thumb on hover */
.volume-adjuster:hover::-webkit-slider-thumb {
    background: #1AA34A; /* Slightly darker green on hover */
}

.volume-adjuster:hover::-moz-range-thumb {
    background: #1AA34A; /* Slightly darker green on hover */
}

/* Responsive Design for Smaller Screens */
@media screen and (max-width: 768px) {
    .volume-adjuster {
        width: 90%; /* Reduce width slightly on smaller screens */
        margin: 15px auto; /* Center and add spacing for touch comfort */
    }

    .volume-adjuster::-webkit-slider-thumb,
    .volume-adjuster::-moz-range-thumb {
        width: 16px; /* Increase thumb size for better touch control */
        height: 16px; /* Increase thumb size for better touch control */
    }
}