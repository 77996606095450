/* Updated Styling */
.timer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: radial-gradient(circle at top, #80deea, #26a69a); /* Modern gradient background */
    font-family: 'Poppins', sans-serif;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
    transition: background 0.5s ease;
}

/* Dynamic background change based on mode */
.timer-container.session-mode {
    background: radial-gradient(circle at top, #ffebee, #ff5252); /* Red for session mode */
}

.timer-container.break-mode {
    background: radial-gradient(circle at top, #e8f5e9, #66bb6a); /* Green for break mode */
}

.heading {
    font-size: 3rem;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    margin-bottom: 20px;
    letter-spacing: 1px;
}

.timer {
    position: relative;
    width: 360px;
    height: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.timer-circle {
    width: 340px;
    height: 340px;
    border-radius: 50%;
    border: 1px solid #e0e0e0; /* Thinner border */
    background: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(3px);
    position: relative;
}

.timer-time {
    font-family: "Montserrat", sans-serif;
    font-size: 4rem;
    font-weight: 400; /* Thinner font weight */
    color: #333;
    letter-spacing: 1px;
}

.timer-mode-label {
    font-family: "Open Sans", sans-serif;
    font-size: 1.2rem;
    font-weight: 300; /* Thinner font weight */
    color: #666;
    text-transform: uppercase;
    position: absolute;
    bottom: 25px;
    letter-spacing: 1px;
    opacity: 0.85;
}

.timer-mode {
    font-family: "Open Sans", sans-serif;
    font-size: 1.3rem;
    font-weight: 300; /* Thinner font weight */
    color: #fff;
    margin-top: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.timer-mode.work-mode {
    color: #ff5252;
}

.timer-mode.rest-mode {
    color: #90EE90;
}

.timer-buttons {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    gap: 15px;
}

.timer-button {
    padding: 12px 20px;
    border-radius: 50px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    width: 140px;
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    color: #004d40;
}

.timer-button.start-button {
    background-color: #66bb6a;
    color: white;
}

.timer-button.reset-button {
    background-color: #e57373;
    color: white;
}

.timer-button:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* No animation on hover, just color change */
.mode-toggle {
    margin-top: 25px;
}

.mode-toggle button {
    padding: 12px 30px;
    border-radius: 50px;
    border: 2px solid #fff;
    background-color: transparent;
    cursor: pointer;
    font-size: 1.2rem;
    color: white;
    transition: all 0.3s ease;
}

/* Pomodoro Count Icons */
.pomodoro-icon {
    font-size: 2.5rem;
    color: #ff5252;
}

/* Container for Controls */
.controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 500px;
    margin-bottom: 25px;
    gap: 15px; /* Gap between the dropdown blocks */
}

/* Each dropdown block (label and select) */
.controls-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Make dropdowns equal width */
}

/* Label above each dropdown */
.control-label {
    font-size: 1.2rem; /* Slightly larger for readability */
    font-weight: 500;
    color: #004d40;
    margin-bottom: 8px; /* Space between label and dropdown */
    text-align: center; /* Center text */
    text-transform: uppercase;
    letter-spacing: 1px;
}

/* Dropdown styling */
.time-select {
    width: 100%;
    padding: 10px 15px; /* Added padding for a comfortable clickable area */
    border-radius: 8px;
    border: 1px solid #b2dfdb; /* Softer border */
    background-color: #ffffff; /* White background for contrast */
    font-size: 1.1rem; /* Larger font size for better readability */
    color: #004d40; /* Consistent color scheme */
    cursor: pointer;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Softer shadow for depth */
    transition: all 0.3s ease; /* Smooth hover effect */
}

/* Dropdown hover effect */
.time-select:hover {
    background-color: #e0f7fa; /* Light background on hover */
    border-color: #004d40; /* Darker border on hover */
}

/* Disabled dropdown */
.time-select:disabled {
    background-color: #eceff1;
    cursor: not-allowed;
    opacity: 0.6;
}