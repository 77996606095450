/* Modal Overlay */
.combination-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Slightly darker overlay for more focus */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(4px); /* Adds a subtle blur to the background */
}

/* Modal Content */
.combination-modal-content {
    background: #ffffff; /* Bright white background for better contrast */
    padding: 25px 30px;
    border-radius: 20px; /* Softer, more rounded corners */
    width: 90%;
    max-width: 450px; /* Slightly wider for better readability */
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15); /* Enhanced shadow for depth */
    font-family: 'Lato', sans-serif;
    text-align: center;
    position: relative;
    color: #333; /* Darker text color for contrast */
    animation: fadeIn 0.3s ease-out; /* Smooth fade-in effect */
}

/* Fade-in animation for modal */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Modal Heading */
.combination-modal-content h3 {
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6rem; /* Slightly larger font size for headings */
    color: #1DB954; /* Spotify's green for heading */
    font-weight: 400; /* Normal font weight for better visibility */
    letter-spacing: 0.5px; /* Slight letter spacing for readability */
}

/* Input Fields */
.combination-modal-content input {
    margin: 20px auto; /* Center the text box */
    padding: 12px; /* Increased padding for better usability */
    border-radius: 10px; /* Softer rounded corners */
    border: 1px solid #1DB954; /* Green border */
    width: 70%; /* Adjusted width for better alignment */
    font-size: 1rem; /* Standardized font size for consistency */
    background-color: #f9f9f9; /* Subtle gray background for input */
    color: #333; /* Darker text color */
    box-sizing: border-box;
    font-weight: 400; /* Normal font weight */
    display: block; /* Ensure it's centered with auto margins */
    transition: border-color 0.3s ease;
}

.combination-modal-content input:focus {
    border-color: #17a44a; /* Slightly darker green on focus */
    outline: none; /* Remove default outline */
}

/* Sound Options Container */
.combination-sound-options {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); /* Grid layout for options */
    gap: 15px; /* Increased gap between options */
    margin-bottom: 20px; /* Space below the options */
}

/* Individual Sound Option */
.combination-sound-option {
    padding: 10px 12px; /* Adjusted padding for better visual balance */
    background-color: #ffffff; /* Light background */
    border-radius: 12px; /* Softer rounded corners */
    border: 1px solid #1DB954; /* Green border for buttons */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
    color: #333; /* Darker text color */
    text-align: center; /* Center the text in each grid item */
    font-weight: 400; /* Normal font style for better visibility */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
}

.combination-sound-option.selected {
    background-color: #e0f7ea; /* Light green background for selected option */
    color: #1DB954; /* Green text color */
    transform: scale(1.05); /* Slightly larger when selected */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* More pronounced shadow for selected state */
}

/* Hover Effect for Sound Option */
.combination-sound-option:hover {
    background-color: #f5f5f5; /* Slightly darker background on hover */
    transform: scale(1.05); /* Slight scaling on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Enhanced shadow on hover */
}

/* Save Button */
.combination-save-button {
    background-color: #1DB954; /* Spotify green for the button */
    color: white;
    padding: 10px 20px; /* Increased padding for better clickability */
    border: 1px solid #1DB954; /* Green border */
    border-radius: 12px; /* Softer rounded corners */
    cursor: pointer;
    font-size: 1rem; /* Standardized font size for consistency */
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
    margin-top: 25px; /* Space above the button */
    font-weight: 400; /* Normal font style */
}

.combination-save-button:hover {
    background-color: #17a44a; /* Slightly darker green on hover */
    transform: scale(1.05); /* Slight scaling effect on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Disabled Save Button */
.combination-save-button:disabled {
    background-color: #c2e8c2; /* Light green for disabled state */
    cursor: not-allowed;
    border-color: #b5d6b5; /* Lighter border for disabled state */
}

/* Close Button */
.combination-close-button {
    background: transparent;
    border: none;
    color: #333;
    font-size: 1.4rem; /* Standardized font size */
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    transition: color 0.3s ease, transform 0.2s ease;
}

.combination-close-button:hover {
    color: #1DB954; /* Spotify green on hover */
    transform: rotate(90deg); /* Add a rotating effect on hover for fun */
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
    .combination-modal-content {
        padding: 20px;
        max-width: 90%; /* Maintain responsive width */
    }

    .combination-modal-content h3 {
        font-size: 1.4rem; /* Slightly smaller font size for the title on tablets */
    }

    .combination-save-button {
        font-size: 0.9rem;
        padding: 8px 16px; /* Adjust padding */
    }

    .combination-close-button {
        font-size: 1.2rem; /* Adjusted size for better UX */
    }
}

@media screen and (max-width: 480px) {
    .combination-modal-content {
        padding: 15px;
        max-width: 95%; /* Increase width slightly for mobile */
    }

    .combination-modal-content h3 {
        font-size: 1.2rem; /* Smaller font size for the title on mobile */
    }

    .combination-save-button {
        font-size: 0.85rem; /* Slightly smaller font size */
        padding: 7px 14px; /* Adjust padding */
    }

    .combination-close-button {
        font-size: 1.1rem; /* Adjusted close button size on mobile */
    }
}

/* Container for the disabled save message */
.combination-save-disabled {
    margin-top: 20px;
    text-align: center;
}

/* Message displayed when save is disabled */
.save-disabled-message {
    color: #555; /* Slightly lighter text color for distinction */
    margin-bottom: 10px;
    font-weight: 400; /* Normal font style for readability */
}

/* Button to prompt sign-up */
.signup-link {
    background-color: #1DB954; /* Spotify green */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
    font-weight: 400; /* Normal font style */
    margin-top: 15px;
}

.signup-link:hover {
    background-color: #17a44a; /* Slightly darker green on hover */
    transform: scale(1.05); /* Slight scaling effect on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Sound Option Icon */
.sound-icon {
    width: 24px; /* Set width for icons */
    height: 24px; /* Set height for icons */
    margin-right: 8px; /* Spacing between icon and text */
    vertical-align: middle; /* Align icon with text */
}