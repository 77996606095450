/* Navbar Container */
.navbar-container {
    position: relative;
    max-width: 160px; /* Set a fixed maximum width */
    min-width: 60px;  /* Set a fixed minimum width */
    transition: all 0.3s ease; /* Add smooth transitions for any size adjustments */
}

.navbar-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff; /* White background */
    padding: 10px;
    width: 70px; /* Fixed width */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 1000;
    transition: width 0.2s ease, box-shadow 0.2s ease;
}

/* Remove hover effect */
.navbar-vertical:hover {
    width: 70px; /* Keep the width fixed when hovered */
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2); /* Optional: you can keep this hover effect for the shadow if you like */
}

/* Profile Picture Section */
.navbar-profile-section {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.navbar-profile-section:hover {
    transform: scale(1.05);
}

/* Profile Picture */
.navbar-profile-picture {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Initials Circle */
.navbar-initials-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #1db954;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Dropdown Menu */
.navbar-dropdown-menu {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    transition: opacity 0.3s ease;
}

/* Menu Items */
.navbar-menu-item {
    width: 50px;
    height: 50px;
    background-color: #f9f9f9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-menu-item:hover {
    background-color: #1db954;
    color: white;
    transform: scale(1.1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.navbar-menu-item .navbar-menu-icon {
    width: 30px;
    height: 30px;
    fill: #333;
    transition: fill 0.3s ease;
}

.navbar-menu-item:hover .navbar-menu-icon {
    fill: white;
}

/* Sound Button Section */
.navbar-sound-section {
    margin-top: 20px;
}

/* Sound Button */
.navbar-sound-btn {
    width: 50px;  /* Set a fixed width */
    height: 50px; /* Set a fixed height */
    background-color: #f9f9f9;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.navbar-sound-btn:hover {
    background-color: #1db954;
    transform: scale(1.1);  /* Scale slightly on hover */
}

.navbar-sound-btn .navbar-menu-icon {
    width: 24px; /* Size of the icon inside */
    height: 24px;
}

/* Navbar Login Button (Styled like Profile Picture but with text) */
.navbar-login-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1db954; /* Spotify green */
    padding: 0 8px; /* Reduced padding for smaller button */
    height: 40px; /* Reduced height for smaller size */
    border-radius: 12px; /* Adjusted for smaller pill shape */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    cursor: pointer; /* Indicate it's clickable */
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    text-decoration: none; /* Remove underline from link */
    border: 2px solid transparent; /* Subtle border */
}

/* Hover effect for login button */
.navbar-login-btn:hover {
    background-color: #148d3d; /* Darker green on hover */
    transform: scale(1.05); /* Slight zoom on hover */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* More pronounced shadow */
    border-color: #148d3d; /* Add border on hover */
}

/* Text inside the login button */
.navbar-login-text {
    font-size: 0.875rem; /* Keep the same font size */
    color: white; /* Text color */
    font-weight: bold; /* Make the text bold */
    text-transform: uppercase; /* Uppercase text for emphasis */
    font-family: 'Roboto', sans-serif; /* Use a clean, modern font */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ensure the text is vertically centered */
    white-space: nowrap; /* Prevent text wrapping */
}

.navbar-menu-icon {
    width: 24px; /* Size of the login SVG icon */
    height: 24px;
    fill: white; /* Icon color */
}

/* For consistency, apply the same effects as the profile picture */
.navbar-profile-picture {
    margin-bottom: 10px;
}