.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  margin: 0 auto;
  padding: 25px 30px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
  transition: all 0.3s ease; /* Smooth transition for dynamic changes */
}

.auth-container h2 {
  margin-bottom: 25px;
  color: #1DB954;
  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
}

.auth-container input {
  width: 100%;
  padding: 12px 40px 12px 12px; /* Padding adjusted for icon space */
  margin: 10px 0;
  border: 2px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
  font-family: 'Roboto', sans-serif;
  background-image: none; /* Remove default input styles */
  background-position: 10px center; /* Icon positioning */
  background-repeat: no-repeat;
}

.auth-container input:focus {
  border-color: #1DB954;
  outline: none;
}

.auth-container button {
  width: 100%;
  padding: 12px;
  margin: 15px 0;
  background-color: #1DB954;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
}

.auth-container button:hover {
  background-color: #1aa34a;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adding shadow on hover */
}

.auth-container button:active {
  background-color: #178c3f;
  transform: scale(1);
  box-shadow: none; /* Remove shadow on click */
}

.input-error {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.875rem;
  margin: 5px 0;
  background-color: #ffefef; /* Light red background for error message */
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
}

.auth-message {
  color: green;
  font-size: 0.9rem;
  margin-top: 15px;
  background-color: #e7f8e8; /* Light green background for success message */
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 12px;
  cursor: pointer;
  color: #888;
  transition: color 0.3s ease;
}

.password-toggle:hover {
  color: #1DB954;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .auth-container {
    max-width: 300px;
    padding: 20px 25px;
  }

  .auth-container h2 {
    font-size: 1.6rem;
  }

  .auth-container input {
    padding: 10px 35px 10px 10px; /* Adjusted padding for smaller screens */
    font-size: 0.95rem;
  }

  .auth-container button {
    padding: 10px;
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .auth-container {
    max-width: 100%;
    padding: 15px 20px;
  }

  .auth-container h2 {
    font-size: 1.4rem;
  }

  .auth-container input {
    padding: 8px 30px 8px 8px; /* Adjusted padding for mobile */
    font-size: 0.9rem;
  }

  .auth-container button {
    padding: 8px;
    font-size: 0.9rem;
  }
}

/* Add to your existing CSS */
.password-container {
  position: relative;
  width: 100%;
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #888;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.password-toggle:hover {
  color: #1DB954;
}
