.footer {
    background-color: #ecb464;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-links li a {
    color: white;
    text-decoration: none;
    font-size: 13px;
    transition: all 0.2s ease-in-out;
}

.footer-links li a:hover {
    text-decoration: underline;
    transform: scale(1.1);
}

/* Media Queries for Responsiveness */

@media screen and (max-width: 768px) {
    .footer {
        padding: 10px 0;
        font-size: 13px;
    }

    .footer-links {
        gap: 15px;
    }

    .footer-links li a {
        font-size: 12px;
    }
}

@media screen and (max-width: 480px) {
    .footer {
        padding: 8px 0;
        font-size: 12px;
    }

    .footer-links {
        gap: 10px;
    }

    .footer-links li a {
        font-size: 11px;
    }
}

@media screen and (max-width: 320px) {
    .footer {
        padding: 6px 0;
        font-size: 11px;
    }

    .footer-links {
        gap: 8px;
    }

    .footer-links li a {
        font-size: 10px;
    }
}