/* General Styling */
.about-page-container {
    background-color: #f7f9fc;
    color: #333;
    line-height: 1.6;
    font-family: 'Fira Code', 'Courier New', Courier, monospace;  /* Monospaced font for coding style */
    padding: 0 20px;
}

/* Header Section */
.about-header {
    background-color: #ffffff;
    padding: 20px 0;
    border-bottom: 1px solid #dfe1e5;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* About Section */
.about-section {
    padding: 60px 0;
    background-color: #ffffff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;  /* Stack items vertically on smaller screens */
}

.about-content {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 900px;  /* Increased max-width for larger content area */
    margin: auto;
    gap: 30px;
    flex-wrap: wrap;  /* Allow items to wrap on smaller screens */
}

.about-image-container {
    flex-shrink: 0;
    margin-bottom: 20px;  /* Add space below image on smaller screens */
}

.about-image {
    width: 200px;  /* Increased width for a bigger appearance */
    height: 200px;  /* Increased height to match the width */
    border-radius: 50%;  /* Circular shape */
    object-fit: cover;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.about-text {
    text-align: left;
    flex-basis: 60%;  /* Allow text to take 60% width on larger screens */
}

/* Adjust text size and spacing for different devices */
.about-text h1 {
    font-size: 2rem;
    margin-bottom: 15px;
}

.about-text p {
    font-size: 1rem;
    margin-bottom: 15px;
    color: #555;
}

.about-cta {
    display: inline-block;
    padding: 12px 30px;
    background-color: #1db954;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

.about-cta:hover {
    background-color: #17a84e;
}

/* Contact Section */
.contact-section {
    padding: 40px 0;
    background-color: #eaf4fd;
    text-align: center;
}

.contact-info {
    list-style: none;
    margin-top: 20px;
}

.contact-info li {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #333;
}

.contact-info a {
    color: #1db954;
    text-decoration: none;
}

.contact-info a:hover {
    text-decoration: underline;
}

/* Footer Section */
.about-footer {
    background-color: #ffffff;
    padding: 20px 0;
    border-top: 1px solid #dfe1e5;
    text-align: center;
    color: #555;
}

/* Media Queries for Responsiveness */

/* For tablets and larger screens */
@media (min-width: 768px) {
    .about-section {
        flex-direction: row;  /* Align items in a row on larger screens */
    }

    .about-text {
        text-align: left;  /* Align text to left on larger screens */
    }

    .about-content {
        flex-direction: row;  /* Align image and text in a row */
    }
}

/* For smaller screens (like mobile devices) */
@media (max-width: 767px) {
    .about-text h1 {
        font-size: 1.8rem;
    }

    .about-text p {
        font-size: 0.9rem;
    }

    .about-image {
        width: 150px;  /* Adjusted image size for mobile devices */
        height: 150px;  /* Adjusted image size to maintain circular shape */
    }
}