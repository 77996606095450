/* MainComponent.css */

/* General container styling */
.main-container {
    padding: 20px;
    margin: 0 auto;
    max-width: 1200px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Header styling */
.main-header {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

/* Paragraph styling */
.main-paragraph {
    font-size: 1rem;
    line-height: 1.5;
    color: #666;
    margin-bottom: 20px;
}

/* Button styling */
.main-button {
    padding: 10px 20px;
    background-color: #ff8e3a;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.main-button:hover {
    background-color: #e57c2e;
}

/* Flexbox container */
.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

/* Flexbox item */
.flex-item {
    flex: 1;
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
}

/* Image styling */
.main-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
