/* Center the login card */
.login-container {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items to the start, which moves them up */
  height: 100vh;
  background-color: #E8F5E9; /* Light green background */
  padding: 20px;
  padding-top: 50px; /* Add padding at the top to move the card up */
}

/* Style the login card */
.login-card {
  background-color: white;
  padding: 30px;
  border-radius: 15px; /* More rounded corners */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Softer shadow */
  text-align: center;
  max-width: 400px;
  width: 100%;
  font-family: 'Roboto', sans-serif; /* Modern, clean font */
}

/* Style the heading */
.login-card h2 {
  margin-bottom: 20px;
  color: #1DB954; /* Spotify green for heading */
  font-size: 1.8rem;
  font-weight: 500;
}

/* Style the paragraph */
.login-card p {
  margin-bottom: 30px;
  color: #666; /* Softer gray for paragraph text */
  font-size: 1rem;
}

/* Style the login button */
.login-button {
  background-color: #1DB954; /* Spotify green */
  color: white;
  border: none;
  border-radius: 25px; /* Rounded pill-shaped button */
  padding: 12px 25px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 20px; /* Add space below the button */
  display: block; /* Ensure button is a block element */
  width: 100%;
}

/* Add hover effect to the button */
.login-button:hover {
  background-color: #1AA34A; /* Slightly darker green on hover */
  transform: scale(1.05); /* Subtle scale effect on hover */
}

/* Style the "Forgot Password?" link */
.reset-password-link {
  display: block; /* Ensure the link is a block element */
  margin-top: 20px;
  color: #1DB954; /* Spotify green for the link */
  text-decoration: none;
  font-size: 0.9rem; /* Slightly smaller font size */
  font-weight: 500; /* Medium weight to make it stand out */
  transition: color 0.3s ease, transform 0.2s ease;
  margin-bottom: 20px; /* Add space below the link */
}

/* Add hover effect to the "Forgot Password?" link */
.reset-password-link:hover {
  color: #1AA34A; /* Slightly darker green on hover */
  transform: scale(1.05); /* Slight zoom effect */
}

/* Style the guest login button */
.guest-login-button {
  background-color: #F5F5F5; /* Light white-grey background */
  color: #333; /* Darker gray text */
  border: none;
  border-radius: 25px; /* Rounded pill-shaped button */
  padding: 10px 20px; /* Slightly smaller padding */
  font-size: 0.9rem; /* Slightly smaller font size */
  cursor: pointer;
  margin-top: 15px; /* Space above the button */
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-family: 'Roboto', sans-serif;
  display: block; /* Ensure button is a block element */
  width: 100%;
}

/* Add hover effect to the guest login button */
.guest-login-button:hover {
  background-color: #E0E0E0; /* Slightly darker light grey on hover */
  transform: scale(1.05); /* Subtle scale effect on hover */
}

/* Media Queries for Responsiveness */

/* For tablets and below */
@media (max-width: 768px) {
  .login-container {
    padding: 15px;
    padding-top: 30px; /* Adjust top padding for tablets */
  }

  .login-card {
    padding: 25px;
    max-width: 350px;
  }

  .login-card h2 {
    font-size: 1.6rem;
  }

  .login-card p {
    font-size: 0.95rem;
    margin-bottom: 20px;
  }

  .login-button,
  .guest-login-button {
    padding: 10px 20px;
    font-size: 0.95rem;
  }

  .reset-password-link {
    font-size: 0.85rem;
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .login-container {
    padding: 10px;
    padding-top: 20px; /* Adjust top padding for mobile devices */
  }

  .login-card {
    padding: 20px;
    max-width: 100%;
  }

  .login-card h2 {
    font-size: 1.4rem;
  }

  .login-card p {
    font-size: 0.9rem;
    margin-bottom: 15px;
  }

  .login-button,
  .guest-login-button {
    padding: 8px 15px;
    font-size: 0.9rem;
  }

  .reset-password-link {
    font-size: 0.8rem;
    margin-top: 15px;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height to center the loader */
}
