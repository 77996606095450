/* Reset Password Container */
.reset-password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
  margin: 0 auto;
  padding: 25px;
  background-color: #F7F9F9; /* Light, soft background */
  border-radius: 15px; /* More rounded corners */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Softer shadow */
  font-family: 'Roboto', sans-serif; /* Modern, clean font */
}

/* Heading Styling */
.reset-password-container h2 {
  margin-bottom: 20px;
  color: #1DB954; /* Spotify green for heading */
  font-size: 1.6rem;
  font-weight: 500;
}

/* Input Field Styling */
.reset-password-container input {
  width: 100%;
  padding: 12px;
  margin: 12px 0;
  border: 1px solid #ccc;
  border-radius: 25px; /* Rounded input fields */
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
}

/* Button Styling */
.reset-password-container button {
  width: 100%;
  padding: 12px;
  margin: 15px 0;
  background-color: #1DB954; /* Spotify green */
  color: white;
  border: none;
  border-radius: 25px; /* Rounded pill-shaped button */
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-family: 'Roboto', sans-serif;
}

/* Button Hover Effect */
.reset-password-container button:hover {
  background-color: #1AA34A; /* Slightly darker green on hover */
  transform: scale(1.05); /* Slight scale effect */
}

/* Back to Sign-In Link */
.back-to-signin-link {
  margin-top: 20px;
  color: #1DB954; /* Spotify green for the link */
  text-decoration: none;
  font-size: 0.9rem; /* Slightly smaller font size */
  font-weight: 500; /* Medium weight for better visibility */
  transition: color 0.3s ease, transform 0.2s ease;
  font-family: 'Roboto', sans-serif;
}

/* Link Hover Effect */
.back-to-signin-link:hover {
  color: #1AA34A; /* Slightly darker green on hover */
  transform: scale(1.05); /* Slight zoom effect */
}
