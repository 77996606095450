/* Container for everything */
.predefined-sessions-and-songs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-left: 120px; /* Account for the navbar width */
    width: calc(100% - 120px); /* Ensure content does not overlap with the navbar */
}

/* Tab Navigation */
.tabs {
    display: flex;
    justify-content: center; /* Center the tabs horizontally */
    margin-bottom: 30px; /* Increased space between tabs and the session content */
    gap: 10px; /* Space between the individual tabs */
    flex-wrap: wrap; /* Allow tabs to wrap to the next line on smaller screens */
}

/* Individual Tab */
.tab {
    padding: 12px 25px;
    border-radius: 50px; /* Make the buttons more rounded */
    background-color: white; /* Default background color for inactive tabs */
    color: black; /* Default font color for inactive tabs */
    cursor: pointer;
    font-weight: 300; /* Thinner font weight */
    font-family: 'Roboto', sans-serif; /* Ensure font consistency */
    border: 2px solid #4CAF50; /* Green border to match the theme */
    transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

/* Active Tab */
.tab.active {
    background-color: #4CAF50; /* Green background for active tab */
    color: white; /* White font color for active tab */
    box-shadow: 0 0 10px rgba(76, 175, 80, 0.6); /* Add a subtle glow to the active tab */
}

/* Hover Effect for Tabs */
.tab:hover {
    background-color: #66BB6A; /* Light green on hover */
    color: white; /* Change font color to white on hover */
    box-shadow: 0 0 15px rgba(76, 175, 80, 0.6); /* Increase the glow on hover */
}

/* Add a musical note icon to the button */
.tab::before {
    content: '♪'; /* Musical note */
    font-size: 1.2rem;
    margin-right: 8px; /* Space between the icon and the text */
    color: inherit; /* Match the icon color with the text color */
}

.tab.active::before {
    content: '♫'; /* Double musical note for active tab */
    font-size: 1.4rem;
}

.tab:hover::before {
    content: '♫'; /* Change to double musical note on hover */
}

/* Media Queries for Responsiveness */

/* Tablet and small desktop */
@media screen and (max-width: 1024px) {
    .predefined-sessions-and-songs {
        margin-left: 100px; /* Adjust for narrower navbar */
        width: calc(100% - 100px); /* Adjust content width accordingly */
    }

    .tab {
        padding: 10px 20px;
        font-size: 0.9rem; /* Slightly smaller font size */
    }

    .tab::before {
        font-size: 1rem; /* Slightly smaller icon size */
    }

    .tab.active::before,
    .tab:hover::before {
        font-size: 1.2rem;
    }
}

/* Mobile devices */
@media screen and (max-width: 768px) {
    .predefined-sessions-and-songs {
        margin-left: 80px; /* Adjust for narrower navbar */
        width: calc(100% - 80px); /* Adjust content width accordingly */
    }

    .tab {
        padding: 8px 18px;
        font-size: 0.85rem; /* Smaller font size for mobile */
    }

    .tab::before {
        font-size: 0.9rem;
    }

    .tab.active::before,
    .tab:hover::before {
        font-size: 1.1rem;
    }
}

/* Extra small devices */
@media screen and (max-width: 480px) {
    .predefined-sessions-and-songs {
        margin-left: 60px; /* Adjust for narrower navbar */
        width: calc(100% - 60px); /* Adjust content width accordingly */
    }

    .tabs {
        gap: 5px; /* Reduce space between tabs */
    }

    .tab {
        padding: 6px 15px;
        font-size: 0.8rem; /* Further reduced font size for smaller screens */
    }

    .tab::before {
        font-size: 0.8rem;
        margin-right: 5px; /* Reduce space between icon and text */
    }

    .tab.active::before,
    .tab:hover::before {
        font-size: 1rem;
    }
}

/* Very small devices */
@media screen and (max-width: 320px) {
    .predefined-sessions-and-songs {
        margin-left: 50px; /* Adjust for narrower navbar */
        width: calc(100% - 50px); /* Adjust content width accordingly */
    }

    .tab {
        padding: 5px 12px;
        font-size: 0.75rem; /* Smallest font size for very small screens */
    }

    .tab::before {
        font-size: 0.7rem;
        margin-right: 4px;
    }

    .tab.active::before,
    .tab:hover::before {
        font-size: 0.9rem;
    }
}